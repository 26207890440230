import React from 'react';

import { wrapper, title, description, button } from './thank-you-box.module.scss';

import Button from '../atoms/button';
import Markdown from '../hoc/markdown';

interface ThankYouBoxProps {
    className?: string;
    data: {
        title: string;
        description: string;
        button?: {
            url?: string | undefined;
            text: string | undefined;
        } | null;
    };
}

const ThankYouBox: React.FC<ThankYouBoxProps> = ({ className, data }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <h2 className={title}>{data.title}</h2>
            <Markdown className={description}>{data.description}</Markdown>
            {data.button?.url && data.button?.text && (
                <Button className={button} to={data.button.url} as={'link'}>
                    {data.button.text}
                </Button>
            )}
        </div>
    );
};

export default ThankYouBox;

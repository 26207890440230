import * as Yup from 'yup';

import { FormGeneratorInitialValues, TFormFieldsFactoryProps } from '../models/form-field.model';
import createConsents from '../utils/create-consents';

export const registrationForm = ({
    consents = [],
    t,
}: TFormFieldsFactoryProps<'RegistrationForm', { consents?: (string | null)[] }>) =>
    [
        {
            name: 'firstName',
            type: 'text',
            label: t.firstName.label,
            initialValue: '',
            schema: Yup.string().required(t.firstName.error.required),
        },
        {
            name: 'lastName',
            type: 'text',
            label: t.lastName.label,
            initialValue: '',
            schema: Yup.string().required(t.lastName.error.required),
        },
        {
            name: 'email',
            type: 'email',
            label: t.email.label,
            initialValue: '',
            schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
        },
        {
            name: 'password',
            type: 'password',
            label: t.password.label,
            initialValue: '',
            schema: Yup.string()
                .min(8, t.password.error.min)
                .max(32, t.password.error.max)
                .required(t.password.error.required),
        },
        ...createConsents(consents, t.consent.error.required, handleCreateConsent),
        {
            name: 'submit',
            type: 'submit',
            label: t.submit.label,
        },
    ] as const;

export type TRegisterFormInitialProps = FormGeneratorInitialValues<
    ReturnType<typeof registrationForm>,
    string
>;

const handleCreateConsent: Parameters<typeof createConsents>[2] = (_consent, index) => {
    if (index > 0) return;
    return {
        name: 'newsletterSubscribe',
        schema: undefined,
    };
};

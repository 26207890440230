import * as Yup from 'yup';

import isNonNullable from './is-non-nullable';
import IFormField from '../models/form-field.model';

export default function createConsents(
    consents: (string | null)[],
    errorText?: string,
    onCreateConsent?: (consent: string | null, index: number) => Partial<IFormField> | undefined
) {
    return consents.filter(isNonNullable).map((consent, index) => {
        return {
            name: `agreement${index}`,
            type: 'checkbox',
            label: consent,
            initialValue: false,
            schema: Yup.boolean().oneOf([true], errorText),
            ...onCreateConsent?.(consent, index),
        };
    });
}

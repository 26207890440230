import React from 'react';
import { useLocation } from '@reach/router';

import HorizontalTabNavBase, { IHorizontalTabNavBaseProps } from './horizontal-tab-nav-base';

export default function HorizontalTabNav({
    className = '',
    itemClassName = '',
    links,
}: IHorizontalTabNavBaseProps) {
    const { pathname } = useLocation();

    return (
        <HorizontalTabNavBase
            className={className}
            itemClassName={itemClassName}
            links={links}
            activeLinkPathname={pathname}
        />
    );
}
